import axios from 'axios'
import {WHATSAPP_URL, SEARCH_WHATSAPP_URL, DELETE_WHATSAPP, LOCAL_URL} from '../utils/index'
import React, {Component} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import {alertService} from "../_services/alert.service";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import AsyncSelect from "react-select/async";
import Chip from "@material-ui/core/Chip";
import Paper from '@material-ui/core/Paper';
import {TableContainer, Tooltip} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';


const useStyles = () => ({
    table: {
        minWidth: 1250,
    },
    container: {
        maxHeight: 800,
    },
});

class ListWhatsappMessages extends Component {
    state = {
        whatsapps: [],
        previous: '',
        next: '',
        loading: true,
        searchTerm: '',
        container_id: '',
        container_no: '',
        open: false,
        openView: false,
        sendView: false,
        deleteView: false,
        whatsappInfo: {
            id: "",
            customer_id: "",
            customer_name: "",
            sender_id: "",
            request: "",
            response: "",
            status: "",
        }
    }

    componentDidMount() {
        this.loadPosts(WHATSAPP_URL).then()
    }

    loadPosts = async (url) => {
        await axios.get(url)
            .then((res) => {
                const whatsapps = res.data.results
                this.setState({
                    whatsapps,
                    previous: res.data.previous,
                    next: res.data.next,
                    loading: false
                })
            })
    }

    loadContainer = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        axios.get(`${LOCAL_URL}cargo/api/list_containers/?search=${inputValue}`).then(response => {
                const json = response.data.results
                callback(json.map(i => ({
                    label: i.container_no + " " + i.destination + " " + i.arrival_date,
                    value: i.id
                })))
            }
        );
    }

    handleSelectContainer = (e) => {
        this.setState({container_id: e.value, container_no: e.label})
    };


    handleDelete = async (event) => {
        event.preventDefault();
        const whatsapp_id = this.state.whatsappInfo.id;
        let DELETE_URL = DELETE_WHATSAPP + whatsapp_id;
        axios.delete(DELETE_URL)
            .then(() => {
                alertService.warn('Success! whatsapp successfully deleted ', {
                    autoClose: true,
                    keepAfterRouteChange: false
                })
                window.scrollTo(0, 0);
            });
        this.setState({deleteView: false})
    }

    handleSendMessage = async (event) => {
        event.preventDefault();

        const container_id = this.state.container_id
        const removeDuplicates = (duplicates) => {
            const flag = {};
            const unique = [];
            duplicates.forEach(elem => {
                if (!flag[elem.customer.id]) {
                    flag[elem.customer.id] = true;
                    unique.push(elem);
                }
            });
            return unique;
        }
        axios.get(`${LOCAL_URL}cargo/api/get_shipped_container/?c=` + container_id).then((response) => {
            const r = response.data;
            if (r.length > 0) {
                const unique_customers = removeDuplicates(r);
                const customers = unique_customers.map(d => ({
                    "customer_id": d.customer.id,
                    "customer_name": d.customer.firstname,
                    "sender_id": d.customer.cellphone,
                }))
                customers.forEach((customer) => {
                    console.log(customer)
                    let MyRequest = "Container Arrival"
                    let MyResponse = 'Habari,\n\n' +
                        'Mzigo wako umewasili kwenye godown letu. Tafadhali fika ofisini kwetu.\n' +
                        '🙏🏽Asante kwa kuichagua GNMCARGO';
                    axios.post(`${LOCAL_URL}cargo/api/whatsapp/`, {
                        customer_id: customer.customer_id,
                        customer_name: customer.customer_name,
                        sender_id: customer.sender_id,
                        request: MyRequest,
                        response: MyResponse,
                        status: 0,
                        type: "Z",
                    }).then(
                        () => {
                                alertService.success('Success! whatsapp successfully sent', {
                                    autoClose: true,
                                    keepAfterRouteChange: false
                                })
                            }
                    ).catch((error) => {
                        console.error(error);
                    });
                })
            } else {
                alertService.warn('Sorry! This container is empty.', {
                    autoClose: true,
                    keepAfterRouteChange: false
                })
            }
        });
        this.setState({sendView: false})

    }


    prevPage = () => {
        this.setState({loading: true})

        if (this.state.previous !== null) {
            let http_link = this.state.previous
            let toPage = http_link.replace("http", "https")
            this.loadPosts(toPage).then();
        } else {
            this.loadPosts(this.state.previous).then();
        }
    }

    nextPage = () => {
        this.setState({loading: true})
        if (this.state.next !== null) {
            let http_link = this.state.next
            let toPage = http_link.replace("http", "https")
            this.loadPosts(toPage).then();
        } else {
            this.loadPosts(this.state.next).then();
        }
    }

    searchPage = () => {
        let term = this.state.searchTerm
        if (term !== "") {
            this.setState({loading: true})
            let toPage = SEARCH_WHATSAPP_URL + term;
            this.loadPosts(toPage).then();
        }
    }
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleChangeEdit = (event) => {
        let newArr = this.state.whatsappInfo; // copying the old data array
        newArr[event.target.name] = event.target.value; // replace e.target.value with whatever you want to change it to
        this.setState(newArr);
    };


    handleEdit = async (event) => {
        event.preventDefault();
        const customer_name = this.state.whatsappInfo.customer_name;
        const customer_request = this.state.whatsappInfo.request
        const system_response = this.state.whatsappInfo.response;
        const sender_id = this.state.whatsappInfo.sender_id;
        const sent_status = this.state.whatsappInfo.status;
        const id = this.state.whatsappInfo.id;

        axios.patch(`${LOCAL_URL}cargo/api/whatsapp/${id}/`, {
            customer_name: customer_name,
            sender_id: sender_id,
            request: customer_request,
            response: system_response,
            status: sent_status,
        }).then((res) => {
            alertService.info('Success! ' + res.data.name + ' edited successfully ', {
                autoClose: true,
                keepAfterRouteChange: false
            })
        }).catch(error => console.error(error));
        this.setState({open: false})
    }


    render() {
        const {
            deleteView,
            openView,
            open,
            sendView,
            whatsapps,
            previous,
            next,
            loading,
            searchTerm,
            whatsappInfo
        } = this.state;

        const handleClick = (whatsapp) => {
            this.setState({open: true, whatsappInfo: whatsapp});
        };

        const handleView = (whatsapp) => {
            this.setState({openView: true, whatsappInfo: whatsapp});
        };

        const handleSendView = () => {
            this.setState({sendView: true});
        };

        const handleDeleteView = (whatsapp) => {
            this.setState({deleteView: true, whatsappInfo: whatsapp});
        }

        const handleClose = () => {
            this.setState({open: false, openView: false, deleteView: false, sendView: false});
        };

        const {classes} = this.props;

        const renderDate = (whatsapp) => {
            const created = whatsapp.created_date;
            return created.substr(0, created.indexOf('T'));
        }

        const renderStatus = (whatsapp) => {

            if (whatsapp.status === 1) {
                return <span>
                     <Chip size="small" label="Sent"
                           color="primary"/>
                </span>;
            } else if (whatsapp.status === 0) {
                return <span>
                     <Chip size="small" label="Pending"
                           color="secondary"/>
                </span>;
            }
        }
        return (
            <div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            <div className="header mt-md-5">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h1 className="header-title">
                                                <FontAwesomeIcon icon={faWhatsapp}/>&nbsp;&nbsp;&nbsp;
                                                All Whatsapp Messages
                                            </h1>
                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Button variant="contained"
                                                        startIcon={<FontAwesomeIcon icon={faPaperPlane}/>}
                                                        onClick={() => handleSendView()}>Send WhatsApp
                                                </Button>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/whatsapp/new'}>
                                                    <Button variant="contained"
                                                            startIcon={<FontAwesomeIcon icon={faWhatsapp}/>}>
                                                        Quick Message</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            {loading ? (
                                <div className='text-center'>
                                    <div className='spinner-border text-primary align-content-center' role='status'>
                                        {' '}
                                        <span className='sr-only'>Loading...</span>{' '}
                                    </div>
                                </div>
                            ) : (
                                <div className="content">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <input
                                                name="searchTerm"
                                                id="searchTerm"
                                                type="text"
                                                placeholder="Mobile No"
                                                value={searchTerm}
                                                onChange={this.handleChange}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <button onClick={this.searchPage}>Search
                                            </button>
                                        </div>
                                        <div className='col-auto'>
                                            <div className="pagination">
                                                <button disabled={previous === null} onClick={this.prevPage}>Previous
                                                </button>
                                                &nbsp;&nbsp;
                                                <button disabled={next === null} onClick={this.nextPage}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <TableContainer className={classes.container} component={Paper}>
                                        <Table stickyHeader className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width="10%">Date</TableCell>
                                                    <TableCell width="15%">Customer</TableCell>
                                                    <TableCell width="10%">Phone </TableCell>
                                                    <TableCell width="5%">Request</TableCell>
                                                    <TableCell width="35%">Response</TableCell>
                                                    <TableCell width="5%">Status</TableCell>
                                                    <TableCell width="20%">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {whatsapps.map((whatsapp) => {
                                                    return <>
                                                        <TableRow key={whatsapp.id}>
                                                            <TableCell>{renderDate(whatsapp)}</TableCell>
                                                            <TableCell>{whatsapp.customer_id.firstname}</TableCell>
                                                            <TableCell>{whatsapp.sender_id}</TableCell>
                                                            <TableCell>{whatsapp.request}</TableCell>
                                                            <TableCell>{whatsapp.response}</TableCell>
                                                            <TableCell>{renderStatus(whatsapp)}</TableCell>
                                                            <TableCell>
                                                                <Tooltip title="View">
                                                                    <IconButton aria-label="view"
                                                                                onClick={() => handleView(whatsapp)}>
                                                                        <InfoIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Edit">
                                                                    <IconButton aria-label="update"
                                                                                onClick={() => handleClick(whatsapp)}>
                                                                        <EditIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Delete">
                                                                    <IconButton aria-label="update"
                                                                                onClick={() => handleDeleteView(whatsapp)}>
                                                                        <DeleteIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <Dialog
                        open={sendView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleSendMessage} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Send WhatsApp Message</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Notify customers on arrival of container.
                                </DialogContentText>
                                <DialogContentText>
                                    <div className='row'>
                                        <div className='col-12 col-md-12 col-xl-12'>
                                            <div className="form-group">
                                                <label htmlFor="id_customer">Select Container</label>
                                                <AsyncSelect
                                                    onChange={this.handleSelectContainer.bind(this)}
                                                    placeholder={'type something ...'}
                                                    loadOptions={this.loadContainer}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </DialogContentText>
                                <DialogContentText>
                                    Sms Template: Ndugu Mteja, mzigo wako umewasili kwenye godown letu.
                                    Tafadhali fika ofisini kwetu. Asante kwa kuchagua FIRST VISION CARGO.
                                </DialogContentText>
                                <DialogContentText>
                                    Attention: Please use this service wisely.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    No
                                </Button>
                                <Button
                                    startIcon={<FontAwesomeIcon icon={faPaperPlane}/>}
                                    type='submit'
                                    color="primary">
                                    Send
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={deleteView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleDelete} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Delete Whatsapp Message</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete {whatsappInfo.customer_id.firstname} Message?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    No
                                </Button>
                                <Button type='submit' color="primary">
                                    Yes
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleEdit} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Edit Whatsapp Message Details</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    To edit to this message, please edit the fields below.
                                </DialogContentText>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_customer">Name</label>
                                            <input
                                                type='text'
                                                name='customer_id'
                                                className='form-control'
                                                required='required'
                                                disabled='true'
                                                value={open ? whatsappInfo.customer_id.firstname : ""}
                                                id='id_customer'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_cellphone">Phone Number</label>
                                            <input
                                                type='number'
                                                name='sender_id'
                                                className='form-control'
                                                required='required'
                                                value={open ? whatsappInfo.sender_id : ""}
                                                id='id_cellphone'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_request">Request</label>
                                            <input
                                                type='text'
                                                name='request'
                                                className='form-control'
                                                required='required'
                                                disabled='true'
                                                value={open ? whatsappInfo.request : ""}
                                                id='id_request'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_response">Response</label>
                                            <input
                                                type='text'
                                                name='response'
                                                className='form-control'
                                                required='required'
                                                disabled='true'
                                                value={open ? whatsappInfo.response : ""}
                                                id='id_response'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-12 col-xl-12'>
                                        <div className="form-group">
                                            <label htmlFor="id_request">Status</label>
                                            <select name="status" className="required form-control" required=""
                                                    id="id_unit" value={open ? whatsappInfo.status : ""}
                                                    onChange={this.handleChangeEdit}>
                                                <option value="" selected="">{open ? whatsappInfo.status : ""}</option>
                                                <option value="0">Pending</option>
                                                <option value="1">Sent</option>
                                            </select>
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    Cancel
                                </Button>
                                <Button type='submit' color="primary">
                                    Edit
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={openView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <DialogTitle id="form-dialog-title"> About WhatsApp Message
                        </DialogTitle>
                        <DialogContent>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Customer:</strong> {openView ? whatsappInfo.customer_id.firstname : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Phone Number:</strong> {openView ? whatsappInfo.sender_id : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Request
                                        </strong> {openView ? whatsappInfo.request : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Response</strong> {openView ? whatsappInfo.response : ""}
                                    </Typography>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
}

export default withStyles(useStyles)(ListWhatsappMessages);