import axios from 'axios'
import {CUSTOMERS_URL, ALL_CUSTOMERS_URL, SEARCH_CUSTOMERS_URL, DELETE_CUSTOMERS, LOCAL_URL} from '../utils/index'
import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import {alertService} from "../_services/alert.service";
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import teal from '@material-ui/core/colors/teal';
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {ExportToExcel} from "../_components/ExportToExcel";
import Chip from "@material-ui/core/Chip";
import Paper from '@material-ui/core/Paper';
import {TableContainer, Tooltip} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {DataGrid} from '@material-ui/data-grid';
import Alert from '@material-ui/lab/Alert';

const useStyles = () => ({
    table: {
        minWidth: 1250,
    },
    container: {
        maxHeight: 800,
    },
});

const customStyle = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class ListCustomer extends Component {
    state = {
        customers: [],
        customerList: [],
        containerList: [],
        previous: '',
        next: '',
        loading: true,
        searchTerm: '',
        open: false,
        openView: false,
        profileView: false,
        noContainers: '',
        loading_containers: true,
        deleteView: false,
        customerInfo: {
            id: "",
            firstname: "",
            lastname: "",
            email: "",
            cellphone: "",
            company_name: "",
            address: "",
            id_type: "",
            id_number: "",
        }
    }

    componentDidMount() {
        this.loadPosts(CUSTOMERS_URL).then()
        this.loadCustomers(ALL_CUSTOMERS_URL).then()
    }

    loadPosts = async (url) => {
        await axios.get(url)
            .then((res) => {
                const customers = res.data.results
                this.setState({
                    customers,
                    previous: res.data.previous,
                    next: res.data.next,
                    loading: false
                })
            })
    }

    loadCustomers = async (url) => {
        await axios.get(url)
            .then((res) => {
                const list = res.data;
                const customerList = list.map(obj => {
                        return {
                            date: obj.created_date.substr(0, obj.created_date.indexOf('T')),
                            firstname: obj.firstname,
                            lastname: obj.lastname,
                            email: obj.email,
                            cellphone: obj.cellphone,
                            company_name: obj.company_name,
                            address: obj.address,
                            id_type: obj.id_type,
                            id_number: obj.id_number,
                        }
                    }
                );
                this.setState({customerList: customerList})
            })
    }


    handleDelete = async (event) => {
        event.preventDefault();
        const customer_id = this.state.customerInfo.id;
        const customer_name = this.state.customerInfo.firstname;
        let DELETE_URL = DELETE_CUSTOMERS + customer_id;
        axios.delete(DELETE_URL)
            .then(() => {
                alertService.warn('Success! ' + customer_name + ' successfully deleted ', {
                    autoClose: true,
                    keepAfterRouteChange: false
                })
                window.scrollTo(0, 0);
            });
        this.setState({deleteView: false})
    }


    prevPage = () => {
        this.setState({loading: true})

        if (this.state.previous !== null) {
            let http_link = this.state.previous
            let toPage = http_link.replace("http", "https")
            this.loadPosts(http_link).then();
        } else {
            this.loadPosts(this.state.previous).then();
        }
    }

    nextPage = () => {
        this.setState({loading: true})
        if (this.state.next !== null) {
            let http_link = this.state.next
            let toPage = http_link.replace("http", "https")
            this.loadPosts(http_link).then();
        } else {
            this.loadPosts(this.state.next).then();
        }
    }

    searchPage = () => {
        let term = this.state.searchTerm
        if (term !== "") {
            this.setState({loading: true})
            let toPage = SEARCH_CUSTOMERS_URL + term;
            this.loadPosts(toPage).then();
        }
    }
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleChangeEdit = (event) => {
        let newArr = this.state.customerInfo; // copying the old data array
        newArr[event.target.name] = event.target.value; // replace e.target.value with whatever you want to change it to
        this.setState(newArr);
    };


    handleEdit = async (event) => {
        event.preventDefault();
        const firstname = this.state.customerInfo.firstname;
        const lastname = this.state.customerInfo.lastname;
        const email = this.state.customerInfo.email;
        const cellphone = this.state.customerInfo.cellphone;
        const company_name = this.state.customerInfo.company_name
        const address = this.state.customerInfo.address;
        const id_type = this.state.customerInfo.id_type;
        const id_number = this.state.customerInfo.id_number;
        const user = localStorage.getItem('user_id');
        const id = this.state.customerInfo.id;

        axios.patch(`${LOCAL_URL}cargo/api/update_customers/${id}/`, {
            firstname: firstname,
            lastname: lastname,
            email: email,
            cellphone: cellphone,
            company_name: company_name,
            address: address,
            id_type: id_type,
            id_number: id_number,
            user: user
        }).then((res) => {
            alertService.info('Success! ' + res.data.firstname + ' edited successfully ', {
                autoClose: true,
                keepAfterRouteChange: false
            })
        }).catch(error => console.error(error));
        this.setState({open: false})
    }


    render() {
        const cs = customStyle;
        const {
            customerList,
            deleteView,
            openView,
            profileView,
            loading_containers,
            noContainers,
            open,
            customers,
            previous,
            next,
            loading,
            searchTerm,
            customerInfo
        } = this.state;

        const renderShippingStatus = (params) => {
            if (params.value === 0) {
                return <span>
                            <Chip size="small" label="Booked"
                                  color="primary"/>
                            </span>;
            } else if (params.value === 1) {
                return <span>
                             <Chip size="small" label="Loaded"
                                   color="primary" style={{backgroundColor: "teal"}}/>
                            </span>;
            } else if (params.value === 2) {
                return <span>
                             <Chip size="small" label="Shipped"
                                   color="primary" style={{backgroundColor: "blue"}}/>
                            </span>;
            } else if (params.value === 3) {
                return <span>
                             <Chip size="small" label="Port"
                                   color="primary" style={{backgroundColor: "green"}}/>
                            </span>;
            } else if (params.value === 4) {
                return <span>
                             <Chip size="small" label="Arrived"
                                   color="primary" style={{backgroundColor: "green"}}/>
                            </span>;
            } else if (params.value === 5) {
                return <span>
                             <Chip size="small" label="Canceled"
                                   color="primary" style={{backgroundColor: "red"}}/>
                            </span>;
            }
        }
        const
            columns = [
                {field: 'container_id', headerName: 'ID', width: 90, hide: true},
                {
                    field: 'container_no',
                    headerName: 'Container',
                    width: 150,
                },
                {
                    field: 'shipping_line',
                    headerName: 'Shipping Line',
                    width: 250,
                },
                {
                    field: 'shipping_date',
                    headerName: 'Shipping Date',
                    type: 'date',
                    width: 250,
                },
                {
                    field: 'arrival_date',
                    headerName: 'Arrival Date',
                    type: 'date',
                    width: 250,
                },

                {
                    field: 'shipping_status',
                    headerName: 'Status',
                    sortable: false,
                    width: 200,
                    renderCell: renderShippingStatus,
                },
            ];
        const fileName = "customer_list"; // here enter filename for your excel file

        const handleClick = (customer) => {
            this.setState({open: true, customerInfo: customer});
        };

        const handleView = (customer) => {
            this.setState({openView: true, customerInfo: customer});
        };
        const handleProfile = (customer) => {
            this.setState({
                profileView: true,
                customerInfo: customer,
                loading_containers: true
            });
            const customer_id = customer.id
            const removeDuplicates = (duplicates) => {
                const flag = {};
                const unique = [];
                duplicates.forEach(elem => {
                    if (!flag[elem.container.id]) {
                        flag[elem.container.id] = true;
                        unique.push(elem);
                    }
                });
                return unique;
            }
            axios.get(`${LOCAL_URL}cargo/api/get_customer_container/?c=` + customer_id).then((response) => {
                const r = response.data;
                if (r.length > 0) {
                    const unique_containers = removeDuplicates(r);
                    const container_data = unique_containers.map(d => ({
                        "container_id": d.container.id,
                        "container_no": d.container.container_no,
                        "shipping_line": d.container.shipping_line,
                        "shipping_date": d.container.shipping_date,
                        "arrival_date": d.container.arrival_date,
                        "shipping_status": d.container.shipping_status,
                    }))
                    this.setState({
                        containerList: container_data,
                        loading_containers: false,
                    })
                } else {
                    this.setState({
                        noContainers: true,
                        loading_containers: false,
                    })
                }
            });
        }

        // const handleDeleteView = (customer) => {
        //     this.setState({deleteView: true, customerInfo: customer});
        // }

        const handleClose = () => {
            this.setState({
                open: false,
                openView: false,
                deleteView: false,
                noContainers: false,
                profileView: false,
                containerList: [],
            });
        };

        const {classes} = this.props;

        const renderDate = (customer) => {
            const created = customer.created_date;
            return created.substr(0, created.indexOf('T'));
        }

        const renderStatus = (customer) => {

            if (customer.status === 1) {
                return <span>
                     <Chip size="small" label="Active"
                           color="primary"/>
                </span>;
            } else if (customer.status === 0) {
                return <span>
                     <Chip size="small" label="Inactive"
                           color="secondary"/>
                </span>;
            }
        }

        return (
            <div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            <div className="header mt-md-5">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h1 className="header-title">
                                                <i className="fe fe-users"/>&nbsp;&nbsp;&nbsp;
                                                All Customers
                                            </h1>
                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/customers/new'}>
                                                    <Button variant="contained" startIcon={<AddIcon/>}>Add
                                                        Customers</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <ExportToExcel apiData={customerList} fileName={fileName}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 ">
                            {loading ? (
                                <div className='text-center'>
                                    <div className='spinner-border text-primary align-content-center' role='status'>
                                        {' '}
                                        <span className='sr-only'>Loading...</span>{' '}
                                    </div>
                                </div>
                            ) : (
                                <div className="content">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <input
                                                name="searchTerm"
                                                id="searchTerm"
                                                type="text"
                                                placeholder="Mobile No"
                                                value={searchTerm}
                                                onChange={this.handleChange}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <button onClick={this.searchPage}>Search
                                            </button>
                                        </div>
                                        <div className='col-auto'>
                                            <div className="pagination">
                                                <button disabled={previous === null} onClick={this.prevPage}>Previous
                                                </button>
                                                &nbsp;&nbsp;
                                                <button disabled={next === null} onClick={this.nextPage}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <TableContainer className={classes.container} component={Paper}>
                                        <Table stickyHeader className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>First Name</TableCell>
                                                    <TableCell>Last Name</TableCell>
                                                    <TableCell>Email</TableCell>
                                                    <TableCell>Mobile Number</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {customers.map((customer) => {
                                                    return <>
                                                        <TableRow key={customer.id}>
                                                            <TableCell>{renderDate(customer)}</TableCell>
                                                            <TableCell>{customer.firstname}</TableCell>
                                                            <TableCell>{customer.lastname}</TableCell>
                                                            <TableCell>{customer.email}</TableCell>
                                                            <TableCell>{customer.cellphone}</TableCell>
                                                            <TableCell>{renderStatus(customer)}</TableCell>
                                                            <TableCell>
                                                                <Tooltip title="Shipped Goods">
                                                                    <IconButton aria-label="view"
                                                                                onClick={() => handleProfile(customer)}>
                                                                        <VisibilityIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="View">
                                                                    <IconButton aria-label="view"
                                                                                onClick={() => handleView(customer)}>
                                                                        <InfoIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Edit">
                                                                    <IconButton aria-label="update"
                                                                                onClick={() => handleClick(customer)}>
                                                                        <EditIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>

                                                                {/*<Tooltip title="Delete">*/}
                                                                {/*    <IconButton aria-label="update"*/}
                                                                {/*                onClick={() => handleDeleteView(customer)}>*/}
                                                                {/*        <DeleteIcon fontSize="small"/>*/}
                                                                {/*    </IconButton>*/}
                                                                {/*</Tooltip>*/}
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <Dialog
                        open={deleteView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleDelete} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Delete Customer</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete {customerInfo.firstname}?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    No
                                </Button>
                                <Button type='submit' color="primary">
                                    Yes
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <form onSubmit={this.handleEdit} className="organizer-form">
                            <DialogTitle id="form-dialog-title">Edit Customer Details</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    To edit to this customer, please edit the fields below.
                                </DialogContentText>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_firstname">First Name</label>
                                            <input
                                                type='text'
                                                name='firstname'
                                                className='form-control'
                                                required='required'
                                                value={open ? customerInfo.firstname : ""}
                                                id='id_firstname'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_lastname">Last Name</label>
                                            <input
                                                type='text'
                                                name='lastname'
                                                className='form-control'
                                                required='required'
                                                value={open ? customerInfo.lastname : ""}
                                                id='id_lastname'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_email">Email</label>
                                            <input
                                                type='email'
                                                name='email'
                                                className='form-control'
                                                required='required'
                                                value={open ? customerInfo.email : ""}
                                                id='id_email'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_cellphone">Phone Number</label>
                                            <input
                                                type='number'
                                                name='cellphone'
                                                className='form-control'
                                                required='required'
                                                value={open ? customerInfo.cellphone : ""}
                                                id='id_cellphone'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_number">ID Type</label>
                                            <select name="id_type" className="required form-control" required=""
                                                    id="id_type" value={open ? customerInfo.id_type : ""}
                                                    onChange={this.handleChangeEdit}>
                                                <option value="" selected="">{open ? customerInfo.id_type : ""}</option>
                                                <option value="1">National ID</option>
                                                <option value="2">Passport</option>
                                                <option value="3">Driving Licence</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_number">ID Number</label>
                                            <input
                                                type='text'
                                                name='id_number'
                                                className='form-control'
                                                required='required'
                                                value={open ? customerInfo.id_number : ""}
                                                id='id_number'
                                                onChange={this.handleChangeEdit}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-12 col-xl-12'>
                                        <div className="form-group">
                                            <label htmlFor="id_address">Address</label>
                                            <input
                                                type='text'
                                                name='address'
                                                className='form-control'
                                                required='required'
                                                value={open ? customerInfo.address : ""}
                                                id='id_address'
                                                onChange={this.handleChangeEdit}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    Cancel
                                </Button>
                                <Button type='submit' color="primary">
                                    Edit
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={openView}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth='sm'
                        fullWidth={true}>
                        <DialogTitle id="form-dialog-title"> About Customer
                        </DialogTitle>
                        <DialogContent>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Full
                                            Name:</strong> {openView ? customerInfo.firstname : ""} {openView ? customerInfo.lastname : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Email:</strong> {openView ? customerInfo.email : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Phone Number:</strong> {openView ? customerInfo.cellphone : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Physical Address:</strong> {openView ? customerInfo.address : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>ID
                                            Type:</strong> {openView ? (customerInfo.id_type === 1 ? 'Passport' : 'National ID') : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>ID Number:</strong> {openView ? customerInfo.id_number : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Created Date:</strong> {openView ? customerInfo.created_date : ""}
                                    </Typography>
                                </div>
                                <div className='col-12 col-md-6 col-xl-6'>
                                    <Typography gutterBottom>
                                        <strong>Created
                                            By:</strong> {openView ? customerInfo.user.first_name + " " + customerInfo.user.last_name : ""}
                                    </Typography>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        fullScreen
                        open={profileView}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                        aria-labelledby="form-dialog-title">
                        <AppBar className={cs.appBar}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                    <CloseIcon/>
                                </IconButton>
                                <Typography variant="h6" className={cs.title}>
                                    {profileView ? customerInfo.firstname : ""}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <div className='pd-top'>
                                {noContainers ? (
                                    <div className="pb-2">
                                        <Alert severity="warning">
                                            Sorry, {profileView ? customerInfo.firstname : ""} has no loaded
                                            consignment!
                                        </Alert>
                                    </div>

                                ) : (
                                    <div className="pb-2">
                                        <Alert severity="info">
                                            Select, Containers to generate invoice
                                            for {profileView ? customerInfo.firstname : ""}!
                                        </Alert>
                                    </div>
                                )}
                                {loading_containers ? (
                                    <div className='text-center'>
                                        <div className='spinner-border text-primary align-content-center'
                                             role='status'>
                                            {' '}
                                            <span className='sr-only'>Loading...</span>{' '}
                                        </div>
                                    </div>
                                ) : (
                                    <div>

                                        <div style={{height: 400, width: '100%'}}>
                                            <DataGrid
                                                getRowId={(r) => r.container_id}
                                                rows={this.state.containerList}
                                                columns={columns}
                                                pageSize={5}
                                                checkboxSelection
                                                disableSelectionOnClick
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Close
                            </Button>
                            {!noContainers && (
                                <Button onClick={handleClose} color="primary">
                                    Generate invoice
                                </Button>
                            )}

                        </DialogActions>
                    </Dialog>
                </div>
            </div>

        )
    }
}

export default withStyles(useStyles)(ListCustomer);
