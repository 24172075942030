import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import axios from "axios";
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import {alertService} from "../_services/alert.service";
import { LOCAL_URL } from '../utils';

class CreateUserPage extends Component {
    componentDidMount() {
        let user_id = localStorage.getItem('user_id');
        let access_token = localStorage.getItem("token")
        // console.log(user_id, access_token)
        this.setState({id: user_id})
        this.setState({token: access_token})
    }

    state = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        phone_no: "",
        role: "",
        errorMessage: "",
        autoClose: false,
        keepAfterRouteChange: false,
        redirectToNewPage: false
    };
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };
    handleSubmit = async (event) => {
        event.preventDefault();
        const first_name = this.state.first_name;
        const last_name = this.state.last_name;
        const email = this.state.email;
        const password = this.state.password;
        const phone_no = this.state.phone_no;
        const role_id = this.state.role;
        const user = localStorage.getItem('user_id');
        // console.log(first_name, last_name, email, password, user, role_id);
        if (role_id === "1") {
            axios.post(`${LOCAL_URL}cargo/users/auth/admin/`, {
                first_name: first_name,
                last_name: last_name,
                password: password,
                email: email,
                cellphone: phone_no,
                role: role_id
            }).then((response) => {
                console.log(response)
                if (response.data.statusCode === 201) {
                    this.setState({redirectToNewPage: true});
                    alertService.success('Success! Admin user successfully added', {
                        autoClose: true,
                        keepAfterRouteChange: false
                    })

                }
            }).catch(err => {
                this.setState({errorMessage: err.message})
                alertService.error('Error :( ' + err.message, {autoClose: true, keepAfterRouteChange: false})

            })
        } else if (role_id === "2") {
            axios.post(`${LOCAL_URL}cargo/users/auth/manager/`, {
                first_name: first_name,
                last_name: last_name,
                password: password,
                email: email,
                cellphone: phone_no,
                role: role_id
            }).then((response) => {
                console.log(response)
                if (response.data.statusCode === 201) {
                    this.setState({redirectToNewPage: true});
                    alertService.success('Success! Manager user successfully added', {
                        autoClose: true,
                        keepAfterRouteChange: false
                    })
                }
            }).catch(err => {
                this.setState({errorMessage: err.message})
                alertService.error('Error :( ' + err.message, {autoClose: true, keepAfterRouteChange: false})
            })
        } else if (role_id === "3") {
            console.log("here")
            axios.post(`${LOCAL_URL}cargo/users/auth/employee/`, {
                first_name: first_name,
                last_name: last_name,
                password: password,
                email: email,
                cellphone: phone_no,
                role: role_id
            }).then((response) => {
                console.log(response)
                if (response.data.statusCode === 201) {
                    this.setState({redirectToNewPage: true});
                    alertService.success('Success! Employee user successfully added', {
                        autoClose: true,
                        keepAfterRouteChange: false
                    })
                }
            }).catch(err => {
                this.setState({errorMessage: err.message})
                alertService.error('Error :( ' + err.message, {autoClose: true, keepAfterRouteChange: false})

            })
        }
    };

    render() {
        const {first_name, last_name, email, password, phone_no, role} = this.state;
        if (this.state.redirectToNewPage) {
            return (
                <Redirect to="/users"/>
            )
        }
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8">
                            {this.state.errorMessage &&
                            <h3 className="error"> {this.state.errorMessage} </h3>}
                            <div className="header mt-md-5">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">

                                            <h1 className="header-title">
                                                <i className="fe fe-user"/>&nbsp;&nbsp;&nbsp;
                                                Add a User
                                            </h1>

                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/users'}>
                                                    <Button variant="contained" startIcon={<i className="fe fe-user"/>}>
                                                        Users</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <form onSubmit={this.handleSubmit} className="organizer-form">
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label for="id_first_name">First Name</label>
                                            <input
                                                type='text'
                                                name='first_name'
                                                placeholder='ex: John'
                                                className='form-control'
                                                required='required'
                                                value={first_name}
                                                id='id_first_name'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label for="id_last_name">Last Name</label>
                                            <input
                                                type='text'
                                                name='last_name'
                                                placeholder='ex: Doe'
                                                className='form-control'
                                                required='required'
                                                value={last_name}
                                                id='id_last_name'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_phone_no">Phone Number</label>
                                            <input
                                                type='number'
                                                name='phone_no'
                                                placeholder='ex: 255714166224'
                                                className='form-control'
                                                required='required'
                                                value={phone_no}
                                                id='id_phone_no'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_role">Role</label>
                                            <select name="role" className="required form-control" required=""
                                                    id="id_role" value={role} onChange={this.handleChange}>
                                                <option value="" selected="">---------</option>
                                                <option value="1">Admin</option>
                                                <option value="2">Manager</option>
                                                <option value="3">Employee</option>
                                            </select>
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_email">Email</label>
                                            <input
                                                type='email'
                                                name='email'
                                                placeholder='ex: John@firstvisioncargo.com'
                                                className='form-control'
                                                required='required'
                                                value={email}
                                                id='id_email'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id-password">Password</label>
                                            <input
                                                type='password'
                                                name='password'
                                                className='form-control'
                                                required='required'
                                                value={password}
                                                id='id-password'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    className='primaryAction btn btn-lg btn-block btn-primary mb-3'
                                    type='submit'
                                >
                                    Create User
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CreateUserPage;
