import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import axios from "axios";
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import {alertService} from "../_services/alert.service";
import AsyncSelect from "react-select/async";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane, faSms} from '@fortawesome/free-solid-svg-icons';
import {password, username, LOCAL_URL} from "../utils";

class CreateSmsMessagePage extends Component {
    state = {
        customer_id: "",
        customer_name: "",
        customer_cellphone: "",
        amount: "",
        unit: "",
        quantity: "",
        message: "",
        errorMessage: "",
        autoClose: false,
        keepAfterRouteChange: false,
        redirectToNewPage: false
    };

    loadOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        axios.get(`${LOCAL_URL}cargo/api/list_customers/?search=${inputValue}`).then(response => {
                const json = response.data.results
                callback(json.map(i => ({
                    label: i.firstname + " " + i.cellphone,
                    value: i.id,
                    name: i.firstname,
                    cellphone: i.cellphone
                })))
            }
        );
    }

    handleChange = (event) => {
        let MyResponse = 'Habari ' + this.state.customer_name + ', tunapenda kukumbusha kujianda kwa malipo ya mzigo' +
            ' wako wenye ' + this.state.quantity + ' ' + this.state.unit + ' kwa gharama ya ' + this.state.amount + 'USD.' +
            ' Ofisi: Msimbazi Kkoo mkabala na AZANIA BANK. Asante kwa kuichagua GNMCARGO.  ';
        this.setState({[event.target.name]: event.target.value, message: MyResponse});
    };

    handleSelectCustomer = (e) => {
        this.setState({customer_id: e.value, customer_name: e.name, customer_cellphone: e.cellphone})
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const customer_id = this.state.customer_id
        const sender_id = this.state.customer_cellphone
        const message = this.state.message
        const content_type = "application/json";
        if (sender_id.startsWith("255") && sender_id.length === 12) {
            axios.post(`${LOCAL_URL}cargo/api/sms/`, {
                customer_id: customer_id,
                sender_id: sender_id,
                response: message,
                status: 1,
                type: "Bill",
            }).then(
                () => {
                    alertService.success('Success! sms successfully sent', {
                        autoClose: true,
                        keepAfterRouteChange: false
                    })
                    window.scrollTo(0, 0);
                }
            ).catch((error) => {
                console.error(error);
            });
            axios.post('https://apisms.beem.africa/v1/send',
                {
                    source_addr: "INFO",
                    schedule_time: "",
                    encoding: 0,
                    message: message,
                    recipients: [
                        {
                            recipient_id: 1,
                            dest_addr: sender_id,
                        },
                    ],
                },
                {
                    headers: {
                        "Content-Type": content_type,
                        Authorization: "Basic " + btoa(username + ":" + password),
                    }
                }
            ).then(() => {
                alertService.info('Success! sms successfully pushed to Beem', {
                    autoClose: true,
                    keepAfterRouteChange: true
                })
            })
        } else {
            axios.post(`${LOCAL_URL}cargo/api/sms/`, {
                customer_id: customer_id,
                sender_id: sender_id,
                response: message,
                status: 0,
                type: "Bill",
            }).then(
                () => {
                    alertService.warn('Sorry! Failed to send sms in correct phone number ' + sender_id, {
                        autoClose: false,
                        keepAfterRouteChange: true
                    })
                    window.scrollTo(0, 0);
                }
            ).catch((error) => {
                console.error(error);
            });
        }
        this.setState({redirectToNewPage: true});
    };

    componentDidMount() {
        let user_id = localStorage.getItem('user_id');
        let access_token = localStorage.getItem("token")
        this.setState({id: user_id})
        this.setState({token: access_token})
    }

    render() {
        const {
            unit,
            amount,
            quantity,
            message
        } = this.state;
        if (this.state.redirectToNewPage) {
            return (
                <Redirect to="/sms"/>
            )
        }
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 col-xl-12">

                            <div className="header mt-md-3">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">

                                            <h1 className="header-title">
                                                <FontAwesomeIcon icon={faSms}/>&nbsp;&nbsp;&nbsp;
                                                Create Quick Sms
                                            </h1>

                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/sms'}>
                                                    <Button variant="contained"
                                                            startIcon={<FontAwesomeIcon icon={faSms}/>}>
                                                        All Sms</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <form onSubmit={this.handleSubmit} className="organizer-form">
                                <div className='row'>
                                    <div className='col-12 col-md-4 col-xl-4'>
                                        <div className="form-group">
                                            <label htmlFor="id_customer">Customer</label>
                                            <AsyncSelect
                                                onChange={this.handleSelectCustomer.bind(this)}
                                                placeholder={'type something ...'}
                                                loadOptions={this.loadOptions}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-2 col-xl-2'>
                                        <div className="form-group">
                                            <label htmlFor="unit">Unit</label>
                                            <select name="unit" className="required form-control" required="required"
                                                    id="unit" value={unit} onChange={this.handleChange}>
                                                <option value="" selected="">---------</option>
                                                <option value="Pkgs">Packages</option>
                                                <option value="Bale">Bale</option>
                                            </select>
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 col-xl-3'>
                                        <div className="form-group">
                                            <label htmlFor="quantity">Quantity</label>
                                            <input
                                                type='text'
                                                name='quantity'
                                                placeholder='ex: 1'
                                                className='form-control'
                                                required='required'
                                                value={quantity}
                                                id='quantity'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 col-xl-3'>
                                        <div className="form-group">
                                            <label htmlFor="amount">Amount</label>
                                            <input
                                                type='text'
                                                name='amount'
                                                placeholder='ex: 100'
                                                className='form-control'
                                                required='required'
                                                value={amount}
                                                id='amount'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-12 col-xl-12'>
                                        <div className="form-group">
                                            <label htmlFor="message">Message</label>
                                            <textarea
                                                name='message'
                                                className='form-control'
                                                required='required'
                                                value={message}
                                                id='message'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-4 col-xl-4'>
                                        <Button
                                            startIcon={<FontAwesomeIcon icon={faPaperPlane}/>}
                                            type='submit'
                                            variant="contained"
                                            color="primary">
                                            Send
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CreateSmsMessagePage;