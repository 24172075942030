import React, {Component} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Redirect} from "react-router";
import HomeIcon from '@material-ui/icons/Home';
import Button from '@material-ui/core/Button';
import {alertService} from "../_services/alert.service";
import {LOCAL_URL} from "../utils";

class CreateCustomerPage extends Component {
    componentDidMount() {
        let user_id = localStorage.getItem('user_id');
        let access_token = localStorage.getItem("token")
        // console.log(user_id, access_token)
        this.setState({id: user_id})
        this.setState({token: access_token})
    }

    state = {
        first_name: "",
        last_name: "",
        email: "",
        cellphone: "",
        company_name: "",
        address: "",
        id_type: "",
        id_number: "",
        errorMessage: "",
        autoClose: false,
        keepAfterRouteChange: false,
        redirectToNewPage: false
    };
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };
    handleSubmitCustomer = async (event) => {
        event.preventDefault();
        const firstname = this.state.first_name;
        const lastname = this.state.last_name;
        const email = this.state.email;
        const input_cellphone = this.state.cellphone;
        const cellphone = input_cellphone.trim()
        const company_name = this.state.company_name
        const address = this.state.address;
        const id_type = this.state.id_type;
        const id_number = this.state.id_number;
        const user = localStorage.getItem('user_id');
        let MyResponse = '';
        console.log(firstname, lastname, email, cellphone, address, id_type, id_number, user);
        axios.post(`${LOCAL_URL}cargo/api/customers/`, {
            firstname: firstname,
            lastname: lastname,
            email: email,
            cellphone: cellphone,
            company_name: company_name,
            address: address,
            id_type: id_type,
            id_number: id_number,
            user: user,
            status: 1
        }).then(res => {
                if (res.data.status === 1) {
                    let customerName = res.data.firstname;
                    let MyRequest = "New customer account created"
                    MyResponse = 'Ndugu, ' + customerName + '\n\n ' +
                        'Karibu *FIRST VISION CARGO* 😎\n' +
                        'Namba yako ya simu imesajiliwa kwenye mfumo wetu.\n' +
                        'Tuma neno *Habari* Kupata maelezo zaidi.\n\n' +
                        '*MF:* Habari\n';
                    axios.post(`${LOCAL_URL}cargo/api/whatsapp/`, {
                        customer_id: res.data.id,
                        customer_name: customerName,
                        sender_id: res.data.cellphone,
                        request: MyRequest,
                        response: MyResponse,
                        status: 0,
                        type: "F",
                    }).then((response) => {
                        console.log(response + 'Event Logged on DB');
                    }).catch((error) => {
                        console.error(error);
                    });
                    this.setState({redirectToNewPage: true});
                    alertService.success('Success! Customer successfully added', {autoClose:true, keepAfterRouteChange:false})
                }
            }
        ).catch((error) => {
            this.setState({errorMessage: error.message})
            alertService.error('Error :( ' + error.message, {autoClose:true , keepAfterRouteChange: false})
        });
    };

    render() {
        const {first_name, last_name, email, cellphone, address, company_name, id_number, id_type} = this.state;
        if (this.state.redirectToNewPage) {
            return (
                <Redirect to="/customers"/>
            )
        }
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8">

                            <div className="header mt-md-3">
                                <div className="header-body">
                                    <div className="row align-items-center">
                                        <div className="col">

                                            <h1 className="header-title">
                                                <i className='fe fe-users'/> &nbsp;&nbsp;&nbsp;
                                                Add a Customer
                                            </h1>

                                        </div>
                                        <div className='col-auto'>
                                            <div className='row'>
                                                <Link to='/'>
                                                    <Button variant="contained" startIcon={<HomeIcon/>} color='primary'>
                                                        Home</Button>
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={'/customers'}>
                                                    <Button variant="contained"
                                                            startIcon={<i className='fe fe-users'/>}>
                                                        Customers</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <form onSubmit={this.handleSubmitCustomer} className="organizer-form">
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="first_name">First Name</label>
                                            <input
                                                type='text'
                                                name='first_name'
                                                placeholder='ex: John'
                                                className='form-control'
                                                required='required'
                                                value={first_name}
                                                id='first_name'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="last_name">Last Name</label>
                                            <input
                                                type='text'
                                                name='last_name'
                                                className='form-control'
                                                placeholder='ex: Doe'
                                                required='required'
                                                value={last_name}
                                                id='last_name'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type='email'
                                                name='email'
                                                placeholder='email@mail.com'
                                                className='form-control'
                                                required='required'
                                                value={email}
                                                id='email'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="cellphone">Phone Number</label>
                                            <input
                                                type='number'
                                                name='cellphone'
                                                placeholder='ex: 255714166224'
                                                className='form-control'
                                                required=''
                                                value={cellphone}
                                                id='cellphone'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="company_name">Company Name</label>
                                            <input
                                                type='text'
                                                name='company_name'
                                                className='form-control'
                                                required='required'
                                                placeholder='ex: DMM Company Limited'
                                                value={company_name}
                                                id='company_name'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="address">Physical Address</label>
                                            <input
                                                type='text'
                                                name='address'
                                                placeholder='Kariakoo Ilala District DSM'
                                                className='form-control'
                                                required='required'
                                                value={address}
                                                id='address'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_type">Identification Type</label>
                                            <select name="id_type" className="required form-control" required="required"
                                                    id="id_type" value={id_type} onChange={this.handleChange}>
                                                <option value="" selected="">---------</option>
                                                <option value="1">National ID</option>
                                                <option value="2">Passport</option>
                                                <option value="3">Driving Licence</option>
                                            </select>
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-xl-6'>
                                        <div className="form-group">
                                            <label htmlFor="id_number">Identification Number</label>
                                            <input
                                                type='text'
                                                name='id_number'
                                                className='form-control'
                                                required='required'
                                                placeholder='ex: 1999022200001678909'
                                                value={id_number}
                                                id='id_number'
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    className='primaryAction btn btn-lg btn-block btn-primary mb-3'
                                    type='submit'
                                >
                                    Create Customer
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CreateCustomerPage;
